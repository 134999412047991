// Import React and ReactDOM
import React,{} from 'react';
import ReactDOM from 'react-dom/client';

import insdep from './public/insdep'; /*全局类*/

import 'framework7/css/bundle';
import 'framework7-icons';

import './resources/global.module.scss';

import nprogress from "nprogress";
import "nprogress/nprogress.css";

import Framework7 from 'framework7/lite-bundle';
import Framework7React from 'framework7-react';


import Init from './init';

const project = insdep.configuration[insdep.configuration.type];
React.insdep = insdep;
React.insdep.project = project;
React.insdep.configuration.request = project.request;

React.insdep.nprogress = nprogress;

Framework7.use(Framework7React);

ReactDOM.createRoot(document.getElementById('insdep-framework7-h5')).render(React.createElement(Init));
