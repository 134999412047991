import { createSlice } from '@reduxjs/toolkit'

export const userReducer = createSlice({
    name: 'user',
    initialState: {

        id:"",
        name:"",
        sex:0,

        system_id:0,
        system_uuid:"",

        uuid:"",         //用户uuid
        base:{},         //用户基础数据
        identity: "",    //用户身份数据
        token:"",        //用户身份token
        object:"account",   //用户对象类型:默认account,可选：account、student、teacher

        system:{},       //系统信息
        school:{},       //学校数据
        agency:{},       //机构数据
        
        encrypt_userid:"",
        encrypt_useraccount:"",
        encrypt_username:"",
        encrypt_phone:"",

        lng:0,
        lat:0
    },
    reducers: {
        setUserData: (state,data) => {
            //console.log("payload",data.payload.dataset);

            let rows   = data.payload.dataset;
            let object = rows.object;

            let id   = Object.hasOwnProperty.call(rows.base, object+"_id") && rows.base[object+"_id"];
            let name = Object.hasOwnProperty.call(rows.base, object+"_data_name") && rows.base[object+"_data_name"];
            let sex  = Object.hasOwnProperty.call(rows.base, object+"_data_sex_id") && rows.base[object+"_data_sex_id"];
            
                state.id   = id;
                state.name = name;
                state.sex  = sex;

                state.uuid = Object.hasOwnProperty.call(rows.base, object+"_uuid") && rows.base[object+"_uuid"];
                state.base = rows.base;

                state.identity = rows.identity;
                state.token    = rows.token;

                state.object   = object;

                state.system   = Object.hasOwnProperty.call(rows, "system") && rows.system;
                state.school   = Object.hasOwnProperty.call(rows, "school") && rows.school;
                state.agency   = Object.hasOwnProperty.call(rows, "agency") && rows.agency;


                state.system   = Object.hasOwnProperty.call(rows, "system") && rows.system;
                state.school   = Object.hasOwnProperty.call(rows, "school") && rows.school;
                state.agency   = Object.hasOwnProperty.call(rows, "agency") && rows.agency;

                state.encrypt_userid        = Object.hasOwnProperty.call(rows, "encrypt_userid") && rows.encrypt_userid;
                state.encrypt_useraccount   = Object.hasOwnProperty.call(rows, "encrypt_useraccount") && rows.encrypt_useraccount;
                state.encrypt_username      = Object.hasOwnProperty.call(rows, "encrypt_username") && rows.encrypt_username;
                state.encrypt_phone         = Object.hasOwnProperty.call(rows, "encrypt_phone") && rows.encrypt_phone;

            if(Object.hasOwnProperty.call(rows, "system")){
                state.system_id   = Object.hasOwnProperty.call(rows.system, "system_id") && rows.system.system_id;
                state.system_uuid = Object.hasOwnProperty.call(rows.system, "system_uuid") && rows.system.system_uuid;
            }

        },
        setUserLocation:(state,data)=>{
            let rows   = data.payload;

            state.lng = rows.lng;
            state.lat = rows.lat;

        }
    },
})

export const { setUserData,setUserLocation } = userReducer.actions /*暴露reducers内的函数*/
export default userReducer.reducer
