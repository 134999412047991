import { createSlice } from '@reduxjs/toolkit'

export const authReducer = createSlice({
    name: 'auth',
    initialState: {
        state:false, //授权状态
    },
    reducers: {
        setAuthState: (state,data) => {
            state.state  = data.payload;
        }
    },
})

export const { setAuthState } = authReducer.actions /*暴露reducers内的函数*/
export default authReducer.reducer
