import lodash from 'lodash';


class util {
    

    /**判断下级数组是否为对象*/
    static isArrayObject = (arr)=>{  
        for (let i = 0; i < arr.length; i++) {  
            const e = arr[i];
            if(typeof e === "object"){
                return true;
            }
        }  
        return false;  
    }

  

    /**对象转字符串*/
    static objectConverString = (data,field = 'value')=>{
        if(typeof data === "object"){
            if(data){
                if(this.isArrayObject(data)){
                    let array = [];
                    for (let index = 0; index < data.length; index++) {
                        const element = data[index];
                        array.push(element[field]);
                    }
                    return array.join(",");
                }else{
                    return data.join(","); /**提交时，对对象节点进行字符串合并*/
                }
            }else{
                return data;
            }
        }else{
            return data;
        }
        
    }

    /**ID组数据转数组*/
    static stringConverArray = (data,type = "int")=>{
        
        data = data?String(data).split(","):[]; /**对对象ID进行字符串分解*/
        data = data.map((v)=>{ /**重新遍历对象ID，对ID进行int处理*/
            let value = "";
            switch (type) {
                case "string":
                    value = String(v);
                    break;
                case "int":
                default:
                    value = parseInt(v);
                    break;
            }
            return value;
        });

        return data;
    }

    /**去除重复的数组元素*/
    static uniqueArray = (array)=>{
        return array.filter((value, index, self) => {  
            return self.indexOf(value) === index;  
        });
    }

    /**去除空的数组元素*/
    /**过滤掉 null、undefined、空字符串、false、NaN */
    static filterArray = (array,type = "int",args = {})=>{
        args = {...{
            keep:false, /**是否保留数字0*/
        },...args};
        let output = [];
        switch (type) {
            case "string":
                output =  array.filter(item => item != null && item !== "" && item !== false && item !== undefined && !isNaN(item));
            break;

            case "int":
            default:
                if(args.keep){
                    output =  array.filter(item => item != null && item !== "" && item !== false && item !== undefined && !isNaN(item));
                }else{
                    output =  array.filter(item => item != null && item !== "" && item !== false && item !== undefined && !isNaN(item) && parseInt(item) !== 0);
                }
            break;
        }

        return output;
    }


    static  mergeObjects = (obj1, obj2) => {  
        return lodash.merge(obj1, obj2);  
    }  

    static amountToChinese = (num)=>{  
        const fraction = ['角', '分'];  
        const digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];  
        const unit = [['元', '万', '亿'], ['', '拾', '佰', '仟']];  
        const head = num < 0 ? '欠' : '';  
        num = Math.abs(num);  
      
        let s = '';  
      
        // 处理小数部分  
        const decimalPart = num.toString().split('.')[1] || '';  
        for (let i = 0; i < fraction.length; i++) {  
            s += (digit[decimalPart[i] || 0] + fraction[i]).replace(/零./, '');  
        }  
      
        s = s || '整';  
        num = Math.floor(num);  
      
        for (let i = 0; i < unit[0].length && num > 0; i++) {  
            let p = '';  
            for (let j = 0; j < unit[1].length && num > 0; j++) {  
                p = digit[num % 10] + unit[1][j] + p;  
                num = Math.floor(num / 10);  
            }  
            s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s;  
        }  
      
        return head + s.replace(/(零.)*零元/, '元').replace(/(零.)+/g, '零').replace(/^整$/, '零元整');   
    }

    static numberToChinese(num) {  
        if (typeof num !== 'number' || !Number.isInteger(num) || num < 0) {  
          return '输入无效，请输入一个非负整数';  
        }  
          
        const units = ['', '十', '百', '千'];  
        const chars = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];  
        let str = '';  
        //let lastIndex; // 用于记录上一个非零数字的位置，以便处理零  
          
        while (num > 0) {  
          let digit = num % 10; // 取个位数  
          if (digit === 0) {  
            // 如果当前位是零且不是末尾或者上一位不是零，则添加零字符  
            if (str.length > 0 && str[str.length - 1] !== '零') {  
              str = chars[digit] + units[units.length - str.length / 3] + str; // 注意这里要减去已处理的位数来确定单位  
            }  
          } else {  
            // 如果当前位不是零，则直接添加对应的字符和单位  
            str = chars[digit] + ((str.length > 0 && str[0] !== '零') ? units[units.length - str.length / 3] : '') + str;  
            //lastIndex = str.length; // 更新最后一个非零数字的位置  
          }  
          num = Math.floor(num / 10); // 去掉个位数字  
        }  
          
        // 处理不必要的零（连续的零只保留一个）  
        str = str.replace(/零+/g, '零');  
        // 如果字符串以零结尾，则去掉它（除非是整数为零的情况）  
        str = str.replace(/零$/, '');  
        // 如果字符串以单位开头（除了'一十'这种情况），则去掉单位（比如'一百零一'不去掉末尾的'一'）  
        str = str.replace(/^一十/, '十一'); // 特例处理'一十'为'十一'  
        str = str.replace(/^([一二三四五六七八九])十/, '$1十'); // 其他情况保留十，如'二十'、'三十'等  
        str = str.replace(/([一二三四五六七八九])零十/g, '$1十'); // 处理'一零十'、'二零十'等为'一十'、'二十'等  
        // 如果处理完后整个数字变成了零字符串，则说明原始数字就是零，需要特殊返回'零'字符串作为结果。  
        return str === '' ? '零' : str; // 返回最终结果字符串或者特殊情况下的'零'字符串。  
    }  

    static amountFormat = (amount)=>{  
        // 将金额转换为字符串，并使用正则表达式匹配每三位数字  
        const formatted = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");  
        return formatted;  
    }  

    static amount = (value)=>{
        if (value === '' || value === undefined || value === null) {  
            return '0.00';  
        }  
        const numberValue = parseFloat(value);  
        if(isNaN(numberValue)){
            return '0.00';  
        }
        const formattedValue = numberValue.toFixed(2);  
        return formattedValue;  
    }
    
    static number = (value)=>{
        const inputValue = value;  
        if (inputValue === '' || inputValue === undefined || inputValue === null) {   
            return 0;  
        }  
        const numberValue = parseInt(inputValue);  
        if(isNaN(numberValue)){
            return 0; 
        }
        return numberValue; 
    }

}

export default util;

