
import { f7 } from 'framework7-react';

// export const message = (url, params) => {

// }
// export const notification = (url, params) => {
        
// }
// export const modal = (url, params) => {
        
// }

class modal {
    static info = (args = {}) =>{
        if(typeof args === "string"){
            args = {
                title:"提示",
                content:args
            };
        }else{
            args = {
                ...{
                    title:"提示",
                    content:""
                },
                ...args
            };
        }

        f7.dialog.alert(args.content,args.title);
    }
    static success = (args = {}) =>{
        if(typeof args === "string"){
            args = {
                title:"提示",
                content:args
            };
        }else{
            args = {
                ...{
                    title:"提示",
                    content:""
                },
                ...args
            };
        }

        f7.dialog.alert(args.content,args.title);
    }
    static error = (args = {}) =>{
        if(typeof args === "string"){
            args = {
                title:"提示",
                content:args
            };
        }else{
            args = {
                ...{
                    title:"提示",
                    content:""
                },
                ...args
            };
        }

        f7.dialog.alert(args.content,args.title);
    }
    static warning = (args = {}) =>{
        if(typeof args === "string"){
            args = {
                title:"提示",
                content:args
            };
        }else{
            args = {
                ...{
                    title:"提示",
                    content:""
                },
                ...args
            };
        }

        f7.dialog.alert(args.content,args.title);
    }
    static confirm = (args = {}) =>{
        if(typeof args === "string"){
            args = {
                title:"提示",
                content:args
            };
        }else{
            args = {
                ...{
                    title:"提示",
                    content:""
                },
                ...args
            };
        }

        f7.dialog.alert(args.content,args.title);
    }
}

export default modal;

