


class wechat {

    /*构建授权域*/
    static constructDomain = (params = "")=>{

        let url = params?this.constructDomain().replace(/\/$/, '')+params:window.location.href;

        if (url.indexOf("?") !==-1) {
            url = url.split("?")[0];
        }

        url.replace('&from=groupmessage', '');
        url.replace('&from=timeline', '');
        url.replace('&from=singlemessage', '');
        url.replace('&isappinstalled=0', '');

        url.replace('from=groupmessage', '');
        url.replace('from=timeline', '');
        url.replace('from=singlemessage', '');
        url.replace('isappinstalled=0', '');

        return url;
        
    }

}

export default wechat;

