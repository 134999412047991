

import { f7 } from 'framework7-react';

class application {

    static error = (args = {}) =>{
        if(typeof args === "string"){
            args = {
                content:args,
                closeTimeout:5000
            };
        }else{
            args = {
                ...{
                    content:"",
                    closeTimeout:5000
                },
                ...args
            };
        }
        const message = f7.toast.create({
            text: args.content,
            position: 'center',
            closeTimeout: args.closeTimeout,
            destroyOnClose:true //关闭时销毁 toast 实例
        });
        message.open();
    }


    static typeName = (application_mode_id) =>{
		let type = "未知";
        switch (parseInt(application_mode_id)) { //功能模式：0未知1发起2管理3查询4详情5状态6统计
            case 1:
                type = "发起";
            break;
            case 2:
                type = "管理";
            break;
            case 3:
                type = "查询";
            break;
            case 4:
                type = "详情";
            break;
            case 5:
                type = "状态";
            break;
            case 6:
                type = "统计";
            break;
            case 7:
                type = "列表";
            break;
            case 8:
                type = "填报";
            break;
            case 0:
            default:
                type = "未知";
            break;
        }
		return type;
    }

	static typePathName = (application_mode_id) =>{ //目录名
		let type = "not";
        switch (parseInt(application_mode_id)) { //功能模式：0未知1发起2管理3查询4详情5状态6统计
            case 1:
            case 8:
                type = "operate";
            break;
            case 2:
                type = "manage";
            break;
            case 3:
                type = "find";
            break;
            case 4:
                type = "view";
            break;
            case 5:
                type = "state";
            break;
            case 6:
                type = "chart";
            break;
            case 7:
                type = "lists";
            break;
            case 0:
            default:
                type = "not";
            break;
        }
		return type;
    }

    /*工作模型*/
    static workModuleName = (module_id)=>{
        let name = "";
        switch (parseInt(module_id)) {
            case 1:
                name = "采购申请";
            break;
            case 2:
                name = "领用申请";
            break;
            case 3:
                name = "用车申请";
            break;
            case 4:
                name = "报修申请";
            break;
            case 5:
                name = "报销申请";
            break;
            case 7:
                name = "预约申请";
            break;
            case 8:
                name = "会议申请";
            break;
            case 11:
                name = "填报信息";
            break;
            case 12:
                name = "党员之家预约";//场所预约申请
            break;
            default:
                name = "";
            break;
        }
        return name;
    }


    /*应用路径*/
    static path = (item)=>{  
        const type = this.typePathName(item.work_application_data.work_application_mode);
        //console.log(item.work_application_data,"/module/"+item.work_application_data.work_application_module_key+"/"+type+"/"+item.work_application_menu_id+"/"+item.work_application_id+"/"+item.work_application_data.work_application_template_id)
        return "/module/"+item.work_application_data.work_application_module_key+"/"+type+"/"+item.work_application_menu_id+"/"+item.work_application_id+"/"+item.work_application_data.work_application_template_id
    }

    static openURL = (item,userData)=>{
        let params = JSON.parse(item.work_application_menu_url_params);
        let paramArray = [];

        let URL = item.work_application_menu_url;

        if(params.length > 0){
            for (let index = 0; index < params.length; index++) {
                const element = params[index];
                if(parseInt(element.param_type) === 1){
                    let regex = /\{(.*)\}/g;
                    let matches = element.param_value.match(regex);
                        if(matches !==null){
                            matches = matches.map(match => match.slice(1, -1))
                        }

                    let paramValue = element.param_value;
                    if(matches !== null && matches.length > 0){
                        switch (matches[0]) {
                            case "userToken":
                                paramValue = userData.token;
                                break;
                            case "userIdentity":
                                paramValue = userData.identity;
                                break;
                            case "userID_AES256CBC":
                                paramValue = userData.encrypt_userid;
                                break;
                            case "userAccount_AES256CBC":
                                paramValue = userData.encrypt_useraccount;
                                break;
                            case "userName_AES256CBC":
                                paramValue = userData.encrypt_username;
                                break;
                            case "userPhone_AES256CBC":
                                paramValue = userData.encrypt_phone;
                                break;
                            default:
                                paramValue = element.param_value;
                                break;
                        }
                    }
                    paramArray[index] = element.param_name + "="+ paramValue;
                }else{
                    paramArray[index] = element.param_name + "="+ element.param_value;
                }
                
            }
        }
        
        let paramString = "";
        if(paramArray.length > 0){
            paramString = paramArray.join("&");
        }

        if(paramString){
            URL = URL + "?"+ paramString;
        }
        //console.log(URL);
        return URL;
    }


    static openApp = (item,userData)=>{
        f7.dialog.confirm('将从'+item.work_application_menu_external_appname + "中打开当前连接，请确保您的手机中已安装当前应用。","打开第三方应用", function () {
            window.plus.runtime.openURL(this.openURL(item,userData),()=>{
                f7.dialog.alert('打开第三方应用失败，可能是未安装应用。',"提示");
            });
        });
    }

    static openWebView = (item,userData)=>{
        // console.log(item,userData);
        // this.openURL(item,userData)
        if(window.plus){
            let statusBarStyle = null; 
            let w = window.plus.webview.create("_www/resources/webview.html",'linkWebView',{},{url:this.openURL(item,userData)});
            w.onloaded = ()=>{
                let show = ()=>{
                    statusBarStyle = window.plus.navigator.getStatusBarStyle();//获取原始颜色
                    window.plus.navigator.setStatusBarStyle("dark");
                    w.show('slide-in-bottom');
                }
                w.checkRenderedContent({}, (e)=>show(), (e)=>show()); //判断渲染是否完成
            };
            w.onclose = ()=>{
                window.plus.navigator.setStatusBarStyle(statusBarStyle);  //还原颜色
            };
        }
    }
  
    static openCamera = (callback)=>{
        if(window.plus){
            let master = window.plus.webview.currentWebview();
            let statusBarStyle = null; 
            let w = window.plus.webview.create("_www/resources/camera.html",'cameraWebView',{contentAdjust:false
            },{
                masterWebviewID:master.id
            });
            
            w.onloaded = ()=>{
                statusBarStyle = window.plus.navigator.getStatusBarStyle();//获取原始颜色
                                 window.plus.navigator.setStatusBarStyle("dark");
                                 window.plus.navigator.setFullscreen(true); //设置全屏
                                 window.plus.navigator.hideSystemNavigation(); //隐藏虚拟按键
                                 w.show('slide-in-bottom');
            };
            w.onclose = ()=>{
                let photo = window.webviewDataCache.camera;
                let close = window.webviewDataCache.cameraClose === undefined ? 0 : window.webviewDataCache.cameraClose;
                window.webviewDataCache.camera = "";
                window.webviewDataCache.cameraClose = 0; //关闭方法，0取消，1上传后关闭
                window.plus.navigator.setFullscreen(false); //取消全屏
                window.plus.navigator.showSystemNavigation(); //显示虚拟按键
                window.plus.navigator.setStatusBarStyle(statusBarStyle);  //还原颜色
                callback(photo,close)
            };
        }
    }

    static other = (item,userData)=>{
        if(parseInt(item.work_application_menu_type) === 0 && parseInt(item.work_application_id) === 0){
            this.error("当前菜单未绑定应用,请联系管理员");
        }else{
            if(item.work_application_menu_url === ""){
                this.error("当前菜单未设置外部链接,请联系管理员");
            }else{
                if(parseInt(item.work_application_menu_type) === 1){
                    this.openApp(item,userData);
                }else{
                    this.openWebView(item,userData);
                }
            }
        }
    }

    /*工作来源名称：发起来源，0未知，1手机端、2电脑端、3API接口、4系统自动创建	*/
    static workOriginName = (origin_id)=>{
        let name = "";
        switch (parseInt(origin_id)) {
            case 1:
                name = "手机客户端";
            break;
            case 2:
                name = "电脑客户端";
            break;
            case 3:
                name = "API接口";
            break;
            case 4:
                name = "系统创建";
            break;
            default:
                name = "未知来源";
            break;
        }
        return name;
    }


    /*列表中的自定义模板字段值的前置处理*/
    static templateListsFieldValue = (templateItem,value)=>{
        
        let output = "-";
        switch (parseInt(templateItem.work_template_field_components)) {
            case 21:
                try {
                    if(value !== undefined || value !== ""){
                        let outputValue = JSON.parse(value);
                        if(Object.prototype.toString.call(outputValue).slice(8, -1) === "Object"){
                            output = this.workModuleName(outputValue.work_table_module_id)+" ("+outputValue.work_table_number+")";
                            if(Object.hasOwnProperty.call(outputValue, "template")){
                                if(Object.hasOwnProperty.call(outputValue.template, "template_name")){
                                    if(outputValue.template.template_name){
                                        output = outputValue.template.template_name+" ("+output.work_table_number+")";
                                    }
                                }
                            }
                        }
                    }
                } catch (error) {
                    
                }
            break;
            
            default:
                output = value;
            break;
        }
        return output;
    }






}

export default application;

