

import configuration from '../configuration.js'
import axios from 'axios'
import output from './output'
import message from './message'
import qs from 'qs'

axios.defaults.withCredentials = true;

const request = (args = {},response = ()=>{},complete = ()=>{},error = ()=>{})=>{

	const controller = new AbortController();

	args = Object.assign({},{
		signal: controller.signal,

		url: '',
		data: {},
		transformRequest: [
			(data)=>{ // 解决传递数组变成对象的问题
				Object.keys(data).forEach((key) => {
					if ((typeof data[key]) === 'object') {
						data[key] = JSON.stringify(data[key]); // 这里必须使用内置JSON对象转换
					}
				});
				data = qs.stringify(data); // 这里必须使用qs库进行转换
				return data;
			}
		],

		timeout: 10 * 3000, //请求超时时间（10秒后还未接收到数据，就需要再次发送请求）
		//retry: 3, //设置全局重试请求次数（最多重试几次请求）
		//retryDelay: 1000, //设置全局请求间隔
		

		method: 'POST', /**/ 

		headers: {
			'Content-Type':'application/x-www-form-urlencoded' /*防止CORS跨域错误,如果不加当前头信息即便已经在服务器上面设置可跨域，依然会提示错误*/
		},
		withCredentials: true,
		/**
		 * baseURL 将自动加在url前面，除非url是一个绝对 URL。
		 * 它可以通过设置一个 baseURL 便于为 axios 实例的方法传递相对 URLs
		*/
		baseURL: configuration.request.hosts,
	},args);

	// console.log(args.url);

	axios.request(args).then((res) => {
		output.response(res,response);
		complete(true);
	}).catch((err) => {
		if(String(err).search("Network Error") !== -1){
			message.error('请求服务器连接失败');
		}else if(String(err).search("not a function") !== -1){
			message.error('程序错误,没有找到相关函数');
		}else if(err.code === "ERR_NETWORK"){
			message.error('请求网络错误,请检查wifi或4G信号是否正常');
		}else if(String(err.message).search("timeout") !== -1){
			message.error('请求网络超时');
		}else{
			message.error('请求服务器时发生未知错误');
		}

		
		complete(false);
		error(err);
		console.log("Axios Request Catch Error:");
		console.log(JSON.stringify(err));
		console.log(err);
	});

	return controller;
	//// 取消请求
		//controller.abort()

	
};


export default request;