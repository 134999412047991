import configuration from '../configuration.js'
import output from './output'
import request from './request'
import upload from './upload'
import modal from './modal'
import message from './message'
import application from './application'
import util from './util'
import wechat from './wechat'
const insdep = {
	request:request,
	output:output,
	modal:modal,
	message:message,
	application:application,
	configuration:configuration,
	upload:upload,
	util:util,
	wechat:wechat,


	/**
	 * empty
	 * 判断是否为空值
	*/
	empty:(value)=>{
		if(typeof value === "object"){
			if(Object.prototype.toString.call(value).slice(8, -1) === "Object"){ //判断是否为JSON对象
				return Object.keys(value).length === 0 ? true :false; 
			}else{ //反之为数组对象
				return value.length === 0 ? true :false; 
			}
		}else{
			if(value=== undefined || value=== null || value=== "" || isNaN(value)){
				return true;
			}else{
				return false;
			}
		}
	}
};
export default insdep;
