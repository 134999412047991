import { createSlice } from '@reduxjs/toolkit'
import util from 'lodash';
import insdep from '@public/insdep'; /*全局类*/

const configuration  = insdep.configuration;

export const routeReducer = createSlice({
    name: 'router',
    initialState: {
        lists :[...configuration[configuration.type].router.lists],/*路由列表*/
        access :{ /**授权后的额外的列表*/
        },
        menu         :[],
        target       :[],  //目标数据
        targetIndex  :{},  //目标索引
    },
    reducers: {
        setLists: (state,data) => {
            const origin = data.payload;
            if(typeof origin === "object"){
                if(origin instanceof Array){
                    /*如果传入的是数组路由列表，那么合并*/
                    state.lists = [...configuration[configuration.type].router.lists,...state.lists,...data.payload];
                }else{
                    /** 
                     * 如果传入的是对象路由，那么查询该对象是否在路由列表中。
                     * 如果存在于路由列表中，那么替换原有的路由内容
                     * 如果布存在于路由列表中，那么新增路由
                    */

                    /**
                     * 遗留问题：规范、优化遍历写法
                    */
                    const lists = state.lists;
                    var exist = false;
                    
                    for (let index = 0; index < lists.length; index++) {
                        const path = lists[index].path;
                        if(path === origin.path){
                            state.lists[index] = origin;
                            exist = true;
                            continue;
                        }
                    }
                    if(!exist){
                        state.lists.push(origin);
                    }
                }
            }
        },
        removeListsNode: (state,data) => {
            const node = data.payload;
            const lists = state.lists;
            const index  = util.findIndex(lists, ['element',node]);
            lists.splice(index,1);

            state.lists = lists;
            
        },
        setMenu: (state,data) => {
            
            const menu   = data.payload;
            const index  = util.findIndex(state.lists, ['element',"master"]);

            state.menu  = menu;
            

            const lists = [];

            const tree = (data)=>{
                for (let index = 0; index < data.length; index++) {
                    const element = data[index];

                    if(element.children){
                        tree(element.children);
                    }else{
                        if(element.uri_regular === "/" || parseInt(element.menu_default) === 1){
                            lists.push({path:"",element:element.path});
                            lists.push({path:element.uri_regular,element:element.path});
                    
                        }else if(element.uri_regular){
                            lists.push({path:element.uri_regular,element:element.path});
                        }
                    }
                }
            }

            tree(menu);
            state.lists[index]['path']     = "/master/*";
            state.lists[index]['element']  = "master";
            state.lists[index]['children'] = [...lists,...state.access.master];
    
            
            
            // console.log("menuReducer",data.payload);
            // console.log("menuReducer index",index);
            //console.log("menuReducer Lists",lists);

        },
        addMenu: (state,data) => {
            const menu   = data.payload;
            const index  = util.findIndex(state.lists, ['element',"master"]);

            const lists  = state.lists[index]['children'];

            const exist  = util.findIndex(lists, ['path',menu.path]); //检查列表中是否已存在当前路径
            //console.log(exist,menu);
            if(exist === -1){ //如果列表中不存在当前目录，那么追加
                state.lists[index]['children'] = [...lists,...[menu]];
            }

            //console.log([...state.lists[index]['children'],...menu]);

            

        },
        setTarget: (state,data) => {
            state.target = data.payload;
        },
        setTargetIndex: (state,data) => {
            state.targetIndex = data.payload;
        }
    },
})

export const { setLists,removeListsNode,setMenu,addMenu,setTarget,setTargetIndex } = routeReducer.actions /*暴露reducers内的函数*/
export default routeReducer.reducer
/***
 * addMenu使用方法
 * dispatch(addMenu({path:"work_module/:id1/:id2/:id3/:id4/:id5",element:"work_module"}));   
*/

/**
 * 注册路由方法
 * dispatch(addMenu({path:"work_module/:module_name/:module_type/:application_menu_id/:work_application_id/:application_template_id",element:"work_module"})); 
 * dispatch(addMenu({path:"work_detail/:type",element:"work_detail"})); 
*/