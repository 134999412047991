import { configureStore } from '@reduxjs/toolkit'

import userReducer from './reducer/user'
import authReducer from './reducer/auth'
import routerReducer from './reducer/router'

export default configureStore({
    reducer: {
        user:userReducer,
        auth:authReducer,
        router:routerReducer,
    }
})