import React, { useEffect } from 'react';
import {
    App,
    View
} from 'framework7-react';

const Default = () => {
    const projectData = React.insdep.project;
    const routesLists = projectData.router.lists;
    let routes = [];

    for (let index = 0; index < routesLists.length; index++) {
        const rows = routesLists[index];
        let node = {
            path: rows.path,
            asyncComponent: () => import('@pages/'+rows.element),
        };

        if("keepAlive" in rows){
            node["keepAlive"] = rows.keepAlive;
        }
        
        routes.push(node);
    } 
    
    const appParameter = { 
        ...projectData.app, 
        ...{
            routes: routes
        }
    };

    const viewParameter = { 
        ...projectData.view, 
        ...{
            main:true, /*是否为主视图*/
            onViewInit:()=>{},
            onViewResize:()=>{},
        }
    };

    useEffect(()=>{
		//加载成功后
       

        return ()=>{
            //卸载后
        }
    },[]); //eslint-disable-line

    
    return  <React.Fragment>
                <App { ...appParameter }>
                    <View { ...viewParameter }/>
                </App>
            </React.Fragment>
            
}
export default Default;