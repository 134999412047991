
//const configuration = require('../configuration');

import message from './message'
class output {

	/**
	* response 
	* 请求响应
	*
	* @access public
	* @param  response json 响应参数参数
	* @param  callback func 回调
	* @return json
	*/
    static response = (response,callback = ()=>{}) => {
		/*
		config: {transitional: {…}, transformRequest: Array(1), transformResponse: Array(1), timeout: 0, adapter: ƒ, …}
		data: {success: false, code: 1, message: 'Not logged in', error: {…}}
		headers: {cache-control: 'no-store, no-cache, must-revalidate', content-length: '94', content-type: 'application/json;charset=utf-8', expires: 'Thu, 19 Nov 1981 08:52:00 GMT', pragma: 'no-cache'}
		request: XMLHttpRequest {onreadystatechange: null, readyState: 4, timeout: 0, withCredentials: true, upload: XMLHttpRequestUpload, …}
		status: 200
		statusText: "OK"
		*/
		
		
		if(Number(response.status) === 200){

			let res = response.data;
			
			if(typeof res === "string"){
	
				try {
					res = JSON.parse(res);
				} catch(e) {
					if(process.env.NODE_ENV === 'development'){
						message.modal_error({title:"响应数据解析错误",content:response.data});
						console.error('响应数据解析错误',response.data);
					}else{
						message.modal_error({title:"提示",content:"响应数据解析错误，这可能是内部错误引起的，请联系厂商修复。"});
					}
					return false;
				}
			}

			let code = Number(response.data.code);
			if(code === 0){ //请求成功

			}else if(code === 1){ //请求登录验证

				message.success(response.data.message);
				sessionStorage.removeItem("auth");
				document.location.reload();

			}else if(code === 400){//请求无效
				
			}else if(code === 401){//未授权/未登录
				
			}else if(code === 403){//禁止访问/拒绝访问/IP地址拒绝
				
			}else if(code === 404){//页面未找到/资源未找到/模板未找到
				
			}else if(code === 500){//内部错误
				
			}

			callback(response);




		}else{
			message.error('请求失败,ErrorCode ('+response.status+')');
		}
		
	}
}

export default output;

/**
 * 
 * 
*/